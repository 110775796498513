.overflow-handling-container {
  max-width: 100%;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;

  .description {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &.expanded {
    .description {
      display: block;
    }
  }
}

