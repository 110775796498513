.project-card {
  cursor: pointer;
  .project-card-container {
    position: relative;
    background-color: #868e96;
    width: 100%;
    height: 144px;
    max-width: 100%;
    max-height: 144px;
    overflow: hidden;

    .project-card-img {
      width: 100%;
      height: 100%;
      filter: brightness(0.5);
      transition: 0.5s;
    }

    &:hover {
      .project-card-img {
        scale: 1.1;
        filter: brightness(0.8);
      }
    }

    .project-card-info {
      top: 50%;
      right: 20px;
      transform: translate(0, -50%);
      position: absolute;
      color: white;
      font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: bold;
      font-size: 1.1rem;
    }
  }
}