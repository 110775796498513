section.resume-section {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    max-width: 75rem;

    .resume-section-content {
        width: 100%;
    }
}

@include media-breakpoint-up(md) {
    section.resume-section {
        min-height: 100vh;
    }
}

@include media-breakpoint-up(lg) {
    section.resume-section {
        padding-left: 3rem;
        padding-right: 3rem;
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}
